
import { setCheckStatus } from '@/tools/mixin';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType, ref, watch, watchEffect } from 'vue';
import { getCountries } from './api';
import { Country, CountryInfo } from './types';

export default defineComponent({
  name: 'CountryModal',
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    checked: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    loading: {
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ['update:modelValue', 'confirm'],
  setup(props, { emit }) {
    const visible = ref(props.modelValue);
    const isInit = ref(false);
    const initLoading = ref(false);
    const origin = ref<Country>({} as any);
    const countries = ref<Country>({} as any);
    const countryArr = ref<CountryInfo[]>([]);
    /** 初始化国家数据 */
    const initList = async () => {
      const { data } = await getCountries(initLoading);
      if (!data) return;
      let curCountryArr: any[] = [];
      let curCountries = {} as Country;
      for (const key in data.countrys) {
        const list = data.countrys[key] as CountryInfo[];
        list.forEach(item => {
          item.chinese_name += ` (${item.country_code_2})`;
        });
        curCountries[key] = list;
        curCountryArr = curCountryArr.concat(list);
      }
      countries.value = data.countrys;
      origin.value = cloneDeep(countries.value);
      countryArr.value = curCountryArr;
      isInit.value = true;
      originChecked.value = cloneDeep(checkTools.checkState.checkedIds);
    };
    const close = () => {
      emit('update:modelValue', false);
    };
    const checkCountries = ref<string[]>([]);
    const confirm = () => {
      emit('confirm', checkTools.checkState.checkedIds);
    };
    /** 复选框相关工具 */
    const checkTools = setCheckStatus<string>(countryArr, 'country_code_2');
    watchEffect(() => {
      visible.value = props.modelValue;
      if (visible.value) {
        initList();
      }
    });
    watch(
      () => [isInit.value, props.checked],
      () => {
        checkTools.checkState.checkedIds = props.checked;
        checkTools.initCheckAll();
      },
    );

    const keywords = ref('');
    const originChecked = ref<string[]>([]);
    /** 搜索 */
    const search = () => {
      let curCountries = {} as any,
        curChecked: string[] = [];
      if (keywords.value) {
        for (const key in origin.value) {
          const list = origin.value[key] as CountryInfo[];
          curCountries[key] = [] as CountryInfo[];

          list.forEach(item => {
            if (item.chinese_name.toUpperCase().indexOf(keywords.value.toUpperCase()) >= 0) {
              curCountries[key].push(item);
              if (originChecked.value.includes(item.country_code_2)) {
                curChecked.push(item.country_code_2);
              }
            }
          });
        }
      } else {
        curCountries = origin.value;
        curChecked = originChecked.value;
      }
      countries.value = curCountries;

      let curCountryArr: any[] = [];
      for (const key in countries.value) {
        const list = countries.value[key] as CountryInfo[];
        curCountryArr = curCountryArr.concat(list);
      }
      countryArr.value = curCountryArr;
      checkTools.checkState.checkedIds = curChecked;
      checkTools.initCheckAll();
    };

    checkTools.addEventListener('checkOne', e => {
      if (!keywords.value) {
        originChecked.value = cloneDeep(e.checkedIds);
      } else {
        const keys: string[] = cloneDeep(originChecked.value);
        const checkedMap: { [key: string]: string } = {};
        checkTools.checkState.checkedIds.forEach(key => {
          checkedMap[key] = key;
        });
        countryArr.value.forEach(item => {
          const code = item.country_code_2;
          if (checkedMap[code]) {
            if (!keys.includes(code)) keys.push(code);
          } else {
            let i: number;
            if ((i = keys.findIndex(k => k === code)) >= 0) {
              keys.splice(i, 1);
            }
          }
        });
        originChecked.value = keys;
      }
    });

    return {
      visible,
      close,
      confirm,
      initLoading,
      countries,
      isInit,
      checkCountries,
      checkTools,
      keywords,
      search,
    };
  },
});
