
import { PropType, defineComponent } from 'vue';
import LimitInput from '../limitInput/LimitInput.vue';

export default defineComponent({
  name: 'FillInput',
  props: {
    fillText: {
      type: String as PropType<string>,
      required: true,
    },
    modelValue: {
      type: [Number, String] as PropType<string | number>,
    },
    size: {
      type: String as PropType<'small' | 'mini' | 'large'>,
      default: 'large',
    },
    maxlength: {
      type: [Number, String] as PropType<number | string>,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const input = e => {
      emit('update:modelValue', e);
    };
    const fill = () => {
      if (!props.modelValue || !(props.modelValue as string).trim()) {
        emit('update:modelValue', props.fillText);
      }
    };
    const change = e => {
      setTimeout(() => emit('change', e));
    };
    return {
      input,
      fill,
      change,
    };
  },
  components: { LimitInput },
});
