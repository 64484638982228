import { reactive } from 'vue';
import {
  CheckState,
  CheckStatusList,
  CheckTools,
  CheckEventType,
  CheckEventListener,
  CehckEventCallback,
} from './types/mixin';

/**
 * 用于设置复选框的全选相关状态
 * @param list 渲染单选复选框的数组
 */
export const setCheckStatus = <T = string | number>(
  list: CheckStatusList,
  key = 'id',
): CheckTools<T> => {
  const checkState: CheckState<T> = reactive({
    /** 用于绑定到 全选框的v-model */
    checkAll: false,
    /** 用于绑定到 全选框的indeterminate */
    isIndeterminate: false,
    checkedIds: [],
  });

  type EventCbs = {
    [K in CheckEventType]: CehckEventCallback<T>[];
  };

  const eventCallbacks = {} as EventCbs;

  /**
   * 绑定到全选组件的change事件上
   * @param val
   */
  const checkAll = val => {
    checkState.checkedIds = val ? list.value.map(item => item[key]) : [];
    checkState.isIndeterminate = false;
    __doEventCb('checkAll');
    __doEventCb('checkOne', 'checkAll');
  };

  /**
   * 绑定到单选组件的change事件上
   * @param val
   */
  const checkOne = val => {
    const checkedCount = val.length;
    checkState.checkAll = checkedCount === list.value.length;
    checkState.isIndeterminate = checkedCount > 0 && checkedCount < list.value.length;
    __doEventCb('checkOne');
  };

  /**
   * 数据初始化时全选状态，也可已用于刷新全选状态
   */
  const initCheckAll = () => {
    const selLen = checkState.checkedIds.length;
    if (selLen > 0 && selLen < list.value.length) {
      checkState.isIndeterminate = true;
      checkState.checkAll = false;
    } else if (selLen === 0) {
      checkState.checkAll = false;
      checkState.isIndeterminate = false;
    } else {
      checkState.isIndeterminate = false;
      checkState.checkAll = true;
    }

    __doEventCb('init');
  };

  const addEventListener: CheckEventListener<T> = (eventName, callback) => {
    let arr = eventCallbacks[eventName];
    if (!arr) arr = eventCallbacks[eventName] = [];
    arr.push(callback);
  };

  function __doEventCb(eventType: CheckEventType, checkType?: CheckEventType) {
    if (eventCallbacks[eventType]) {
      eventCallbacks[eventType].forEach(cb =>
        cb({ checkedIds: checkState.checkedIds, checkType: checkType || eventType }),
      );
    }
  }

  return {
    checkState,
    /** 绑定到全选组件的change事件上 */
    checkAll,
    /** 绑定到单选组件的change事件上 */
    checkOne,
    /** 数据初始化时全选状态 */
    initCheckAll,
    addEventListener,
  };
};
