import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LimitInput = _resolveComponent("LimitInput")

  return (_openBlock(), _createBlock(_component_LimitInput, {
    placeholder: _ctx.fillText,
    size: _ctx.size,
    modelValue: _ctx.modelValue,
    onInput: _ctx.input,
    onBlur: _ctx.fill,
    onChange: _ctx.change,
    maxlength: _ctx.maxlength,
    disabled: _ctx.disabled
  }, {
    suffix: _withCtx(() => [
      _renderSlot(_ctx.$slots, "suffix")
    ]),
    _: 1
  }, 8, ["placeholder", "size", "modelValue", "onInput", "onBlur", "onChange", "maxlength", "disabled"]))
}