import request from '@/tools/request';
import { Res } from '@/types/common';
import { CountryData } from '../types';

/**
 * 获取国家
 * @param loading
 * @returns
 */
export const getCountries = loading => {
  return request({
    url: '/module-country-country.html',
    loading,
  }) as Promise<Res<CountryData>>;
};
