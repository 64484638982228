
import { getRoute } from '@/tools/config';
import { defineComponent, reactive, ref } from 'vue';
import CountryModal from '@/components/countryModal/CountryModal.vue';
import {
  getChartsData,
  getConfig,
  getLogs,
  getProportion,
  updateConfig,
  getOverage,
} from '../../api/statistics';
import { ElMessage } from 'element-plus';
import Table from '@/components/table/Table.vue';
import { ChartsData, Config, CountryInfo, Proportion } from '../../types/statistics';
import Charts, { ChartsSource } from '@/components/charts/Charts.vue';
import { parseTime } from '@/tools';
import FormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';
import FillInput from '@/components/fillInput/FillInput.vue';
import Tooltips from '@/components/tooltips/Tooltips.vue';

export default defineComponent({
  name: 'Statistics',
  components: { CountryModal, Table, Charts, FormSkeleton, FillInput, Tooltips },
  setup() {
    const getDefault = (): Config => {
      return {
        countries: [],
        notice: 1,
        notice_msg: 'Type a character to enable autofill suggestion in the address bar!',
      };
    };

    const ONE_DAY_MS = 1000 * 60 * 60 * 24;
    const dateSection = ref([new Date(Date.now() - 30 * ONE_DAY_MS), new Date()]);
    const visible = ref(false);
    const modalKey = ref(0);
    const openCountryModal = () => {
      modalKey.value = Date.now();
      visible.value = true;
    };

    // 初始化配置
    const configLoading = ref(false);
    const config = ref(getDefault());
    const initConfig = async () => {
      const { data } = await getConfig(configLoading);
      if (!data) return;
      const defaultConfig = getDefault();
      config.value = {
        countries: data.countries || defaultConfig.countries,
        notice: data.notice || defaultConfig.notice,
        notice_msg: data.notice_msg || defaultConfig.notice_msg,
      };
    };

    const changeCountries = ids => {
      config.value.countries = ids;
      save().then(() => {
        visible.value = false;
      });
    };

    const saveLoading = ref(false);
    const save = async () => {
      if (config.value.notice === 1 && !config.value.notice_msg) {
        return ElMessage.warning('请填写地址补充提示');
      }
      await updateConfig(config.value, saveLoading);
      ElMessage.success('操作成功');
    };

    // 初始化国家比例
    const proportionLoading = ref(false);
    const proportion = ref([] as Proportion[]);
    const searchTotal = ref(0);
    const initProportion = async () => {
      const { data } = await getProportion(
        {
          start_date: parseTime(dateSection.value[0], '{y}-{m}-{d}') as string,
          end_date: parseTime(dateSection.value[1], '{y}-{m}-{d}') as string,
        },
        proportionLoading,
      );
      if (!data) return;
      proportion.value = data;
      proportion.value.forEach(item => {
        searchTotal.value += Number(item.num);
      });
    };

    // 初始化统计图数据
    const chartsData = ref<ChartsData>({ xAxis: [], yAxis: [] });
    const chartsSource = ref<ChartsSource[]>();
    const chartsLoading = ref(false);
    const initCharts = async () => {
      const { data } = await getChartsData(
        {
          start_date: parseTime(dateSection.value[0], '{y}-{m}-{d}') as string,
          end_date: parseTime(dateSection.value[1], '{y}-{m}-{d}') as string,
        },
        chartsLoading,
      );
      if (!data) return;
      chartsData.value = data;
      chartsSource.value = [{ name: '使用次数', data: chartsData.value.yAxis }];
    };

    const changeDate = () => {
      initProportion();
      initCharts();
      initLog();
    };
    // 初始化补全记录
    interface ParseLog {
      place_id: string;
      country_code: string;
      place: string;
      create_at: number;
      country: CountryInfo;
    }
    const logLoading = ref(false);
    const logs = ref<ParseLog[]>([]);
    const pagination = reactive({
      total: 0,
      pageSize: 10,
      currentPage: 1,
    });
    const initLog = async () => {
      const { data } = await getLogs(
        {
          start_date: parseTime(dateSection.value[0], '{y}-{m}-{d}') as string,
          end_date: parseTime(dateSection.value[1], '{y}-{m}-{d}') as string,
          page: pagination.currentPage,
          page_size: pagination.pageSize,
        },
        logLoading,
      );
      if (!data) return;
      pagination.total = Number(data.paginate.count);
      const map: { [key: string]: CountryInfo } = {};
      data.country.forEach(item => {
        map[item.country_code_2] = item;
      });
      logs.value = data.list.map(item => {
        return {
          place_id: item.place_id,
          country_code: item.country_code,
          place: item.place,
          create_at: item.create_at,
          country: map[item.country_code],
        };
      });
    };

    const pageChange = (cur: number) => {
      pagination.currentPage = cur;
      initLog();
    };

    // 初始化余额
    const overage = ref(0);
    const overageLoading = ref(false);
    const initOverage = async () => {
      const { data } = await getOverage(overageLoading);
      overage.value = data?.sub_total || 0;
    };

    return {
      dateSection,
      getRoute,
      openCountryModal,
      pageChange,
      visible,
      modalKey,
      initConfig,
      config,
      save,
      saveLoading,
      changeCountries,
      initProportion,
      changeDate,
      pagination,
      proportion,
      proportionLoading,
      searchTotal,
      initCharts,
      chartsData,
      chartsSource,
      initLog,
      logs,
      logLoading,
      initOverage,
      overage,
      chartsLoading,
      overageLoading,
      parseTime,
      configLoading,
      getDefault,

      tooltipFormatter: (p: any[]) => {
        if (!p || !p.length) return '';
        let text = p[0].axisValue;
        let ret = `<span style="font-size: 12px; color: #999999; margin-bottom: 8px;">时间 : ${text}</span>`;
        const items = p.map(item => {
          return `
          <div style="margin-top: 4px; font-size: 14px;">
            ${item.marker}
            ${item.seriesName}
            <span style="font-weight: bold;">${item.value}</span>
          </div>
        `;
        });

        ret += items.join('');

        return ret;
      },
    };
  },
  mounted() {
    this.initConfig();
    this.initProportion();
    this.initCharts();
    this.initOverage();
    this.initLog();
  },
});
