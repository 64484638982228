
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'LimitInput',
  props: {
    modelValue: [String, Number] as PropType<string | number>,
    placeholder: String as PropType<string>,
    maxlength: [String, Number] as PropType<string | number>,
    disabled: Boolean as PropType<boolean>,
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const input = val => {
      emit('update:modelValue', val);
      emit('input', val);
    };
    const inputRef = ref();
    return {
      focus: () => inputRef.value.focus(),
      input,
    };
  },
});
