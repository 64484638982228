
import { defineComponent, PropType } from 'vue';
export type placeMent =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end';
export default defineComponent({
  name: 'ToolTips',
  props: {
    content: {
      //提示文字
      type: String as PropType<string>,
    },
    placement: {
      type: String as PropType<placeMent>,
      default: 'top',
    },
    width: {
      type: [String, Number] as PropType<string | number>,
    },
  },
});
